import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { I18NKey } from "../i18n";
import DarkLogo from "../images/logo-darksvg.svg";
import Logo from "../images/logo-light.svg";

type Props = {
  topText?: I18NKey;
  bottomText?: I18NKey;
};

export const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Container>
      {props.topText && (
        <IonText color="medium">
          <p>{t(props.topText)}</p>
        </IonText>
      )}
      <IconContainer>
        <img className="logoLight" src={Logo} />
        <img className="logoDark" src={DarkLogo} />
      </IconContainer>
      {props.bottomText && (
        <IonText color="medium">
          <p>{t(props.bottomText)}</p>
        </IonText>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12vh;
  justify-content: center;
  padding: 0 20px;
  margin-bottom:30px;
  text-align: center;
`;

const IconContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  > img {
    width: auto;
    max-height: 100%;
  }
`;
