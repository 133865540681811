import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled, { CSSObject } from "styled-components";
import { storage } from "../data/storage";
import { Icon } from "./Icon";

export const BackActionArrow: React.FC<{
  backgroundColor?: CSSObject["color"];
  action: () => void;
  color?: CSSObject["color"];
}> = ({ backgroundColor, color = "var(--ion-color-medium)", action }) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      onClick={() => action()}
    >
      <Icon size="20px" name="arrow-left" color={color} />
    </Container>
  );
};

const Container = styled.div<{ backgroundColor?: CSSObject["color"] }>`
  width: 25px;
  height: 25px;
  position: fixed;
  left: 20px;
  top: 15px;
  z-index: 110;
  border-radius: 25px;
  padding: 3px 3px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
`;
