import _ from "lodash";
import { Config } from "./config";

import { storage } from "./storage";

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = Config.baseUrl + path;
    if (_.keys(params).length > 0) {
      uri +=
        "?" +
        _.map(params, (value, key) =>
          Array.isArray(value)
            ? value.map((v) => `${key}=${v}`).join("&")
            : `${key}=${value}`
        ).join("&");
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res));
  };

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`);
    }
    if (response.status === 401) {
      storage.deleteToken();
      storage.deleteUser();
    }
    return response
      .json()
      .then((json: { data: T | null; error: null | { code: string } }) => {
        if (response.status !== 200) {
          throw new Error(json.error!.code ?? "invalid_server_response");
        }
        return json.data!;
      });
  };

  private getHeaders = (useToken: boolean, includeJSON: boolean = true) => {
    const token = storage.getToken();
    // console.log(token)
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      ...(includeJSON ? { "Content-Type": "application/json" } : {}),
      Platform: "pwa",
      "User-Language": navigator?.language,
    };
  };

  public get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>("GET", path, undefined, params, useToken);

  public delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>("DELETE", path, undefined, params, useToken);

  public post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>("POST", path, body, undefined, useToken);

  public put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>("PUT", path, body, undefined, useToken);

  public patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>("PATCH", path, body, undefined, useToken);

  public requestMulti = async (path: string, body: FormData) => {
    let uri = process.env.REACT_APP_BASE_URL + path;

    return fetch(uri, {
      method: "POST",
      body: body,
      headers: this.getHeaders(true, false),
    }).then((data) => data.json());
  };

  public cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[];
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== "")
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p));
        }
        return String(param);
      })
      .value();
}

export const api = new API();
