import { api } from "../../../data/api";
import { QuizAnswer } from "../../../utils/const/quizAnswerEnum";

class QuizAPI {
  sendResults = ({
    email,
    first_name,
    last_name,
    result,
  }: {
    email: string;
    first_name: string;
    last_name: string;
    result: QuizAnswer;
  }) =>
    api.post<{ message: string }>("/auth/send-result-email", {
      email,
      first_name,
      last_name,
      result,
    });
}

export const quizAPI = new QuizAPI();
