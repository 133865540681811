import React, { useContext } from "react";
import styled, { CSSObject } from "styled-components";

import { RegisterOptions, UseFormRegister } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";

type Size = "small" | "medium" | "large";
export type InputProps = {
  name: string;
  type?: "text" | "email" | "password" | "date" | "number";
  min?: number;
  max?: number;
  autocomplete?: string;
  size?: Size;
  label?: string;
  placeholder?: string;
  description?: string;
  errorText?: string;
  valid?: boolean;
  disabled?: boolean;
  required?: boolean;
  outlineColor?: CSSObject["color"];
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (s: string) => void;
  value?: string;
  translate?: boolean;
  rows?: number;
  columns?: number;
  rightWidget?: React.ReactNode;
  disabledBackgroundColor?: CSSObject["color"];
  style?: Record<string, unknown>;
  className?: string;
  innerRef?: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
};

const noop = (...s: any[]) => ({});
export function Input({
  name,
  type = "text",
  autocomplete,
  size = "large",
  label,
  translate = true,
  placeholder = label,
  outlineColor = "--ion-color-primary",
  description,
  errorText = description,
  valid = true,
  disabled = false,
  required = false,
  min,
  max,
  onFocus = noop,
  onBlur = noop,
  onChange,
  value,
  rows,
  columns,
  rightWidget,
  disabledBackgroundColor = "--ion-colo-medium-shade",
  style,
  className,
  innerRef,
  registerOptions,
}: InputProps) {
  const { t } = useTranslation();

  let realLabel =
    label !== "" ? (translate ? t(label ?? name) : label ?? name) : "";
  let realPlaceholder =
    placeholder === "" && label !== ""
      ? undefined
      : placeholder
      ? translate
        ? t(placeholder)
        : placeholder
      : `${t("insert_placeholder_infix")} ${translate ? t(name) : name}`;
  if (required) {
    realLabel += " *";
    realPlaceholder += " *";
  }

  return (
    <Container style={style} className={className}>
      {realLabel !== "" && (
        <Label htmlFor={name} color="--ion-color-dark">
          {realLabel}
        </Label>
      )}
      {rows || columns ? (
        <StyledTextArea
          {...{ name, type, onFocus, onBlur, disabled }}
          placeholder={realPlaceholder}
          autoComplete={autocomplete}
          onChange={
            onChange && !disabled ? (ev) => onChange(ev.target.value) : noop
          }
          value={value}
          padding={size}
          focusColor={outlineColor}
          textColor={"--ion-color-dark"}
          blurredColor={"--ion-color-medium"}
          errorColor={valid ? undefined : "--ion-color-danger"}
          backgroundColor="var(--ion-color-white)"
          disabledBackgroundColor={disabledBackgroundColor}
          rows={rows}
          cols={columns}
          {...(innerRef
            ? innerRef(name, { required, ...registerOptions })
            : null)}
        />
      ) : (
        <StyledInputContainer
          padding={size}
          focusColor={outlineColor}
          textColor=" --ion-color-dark"
          blurredColor="--ion-color-light"
          errorColor={valid ? undefined : "--ion-color-danger"}
          disabled={disabled}
          backgroundColor="--ion-color-white"
          disabledBackgroundColor={disabledBackgroundColor}
        >
          <StyledInput
            {...{ name, type, onFocus, onBlur, disabled }}
            placeholder={realPlaceholder}
            size={1}
            min={min}
            max={max}
            autoComplete={autocomplete}
            onChange={
              onChange && !disabled ? (ev) => onChange(ev.target.value) : noop
            }
            value={value}
            padding={size}
            focusColor={outlineColor}
            textColor="#828894"
            blurredColor="--ion-color-medium"
            errorColor={valid ? undefined : "--ion-color-danger"}
            backgroundColor="--ion-color-white"
            disabledBackgroundColor={disabledBackgroundColor}
            {...(innerRef
              ? innerRef(name, { required, ...registerOptions })
              : null)}
          />
          {rightWidget}
        </StyledInputContainer>
      )}
      {description && (
        <Description color="--ion-color-light">{description}</Description>
      )}
      {!valid && (
        <Description color="--ion-color-danger">{errorText}</Description>
      )}
    </Container>
  );
}

export const Container = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

type Padding = { [key in Size]: { x: number; y: number } };
const paddings: Padding = {
  small: { x: 8, y: 10 },
  medium: { x: 16, y: 16 },
  large: { x: 25, y: 19 },
};
type StyledProps = {
  textColor: string;
  blurredColor: string;
  focusColor: string;
  padding: Size;
  backgroundColor: string;
  disabledBackgroundColor: string;
  errorColor?: string;
  disabled?: boolean;
};
export const StyledInput = styled.input<StyledProps>`
  font-size: 13px;
  height: 35px;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 400;
  color: var(
    ${(props) => (props.disabled ? props.blurredColor : props.textColor)}
  );
  background-color: var(
    ${(props) =>
      props.disabled ? props.disabledBackgroundColor : props.backgroundColor}
  );
  border: none;
  margin: 0;
  /* box-sizing: border-box; */
  padding: ${(props) => paddings[props.padding].x}px
    ${(props) => paddings[props.padding].y}px;
  /* border: ${(props) => paddings[props.padding].x}px ${(props) =>
    paddings[props.padding].y}px solid
    ${(props) =>
    props.disabled ? props.disabledBackgroundColor : props.backgroundColor}; */
  outline: none;
  flex-grow: 2;
  ::-webkit-input-placeholder {
    color: var(${(props) => props.blurredColor});
  }
  ::placeholder {
    color: var(${(props) => props.blurredColor});
  }
  :disabled {
    background-color: var(${(props) => props.disabledBackgroundColor});
  }
`;

export const StyledInputContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border: 0.5px solid ${(props) => props.errorColor || "#ccc"};
  margin: 0;
  width: 100%;
  max-width: 100%;
  /* //calc(100% - ${(props) => paddings[props.padding].x * 2}px); */
  border-radius: 4px;
  outline: none;
  background-color: var(
    ${(props) =>
      props.disabled ? props.disabledBackgroundColor : props.backgroundColor}
  );
  :focus-within {
    border: 0.5px solid var(${(props) => props.errorColor || props.focusColor});
  }
`;

const StyledTextArea = styled.textarea<StyledProps>`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => (props.disabled ? props.blurredColor : props.textColor)};
  border: 0.5px solid ${(props) => props.errorColor || "#ccc"};
  margin: 0;
  padding-left: ${(props) => paddings[props.padding].x}px;
  padding-right: ${(props) => paddings[props.padding].x}px;
  padding-top: ${(props) => paddings[props.padding].y}px;
  padding-bottom: ${(props) => paddings[props.padding].y}px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  background-color: ${(props) =>
    props.disabled ? props.disabledBackgroundColor : props.backgroundColor};
  :focus {
    border: 0.5px solid var(${(props) => props.errorColor || props.focusColor});
  }
  :disabled {
    background-color: ${(props) => props.disabledBackgroundColor};
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: var(${(props) => props.blurredColor});
  }
  ::placeholder {
    color: var(${(props) => props.blurredColor});
  }
`;

type StyledLabelProps = { color: string };
export const Label = styled.label<StyledLabelProps>`
  font-size: 13px;
  font-weight: 600;
  color: var(${(props) => props.color});
  margin: 0;
  padding: 0;
  line-height: 20px;
  margin-bottom: 5px;
  display: inline-block;
`;

export const Description = styled.span<StyledLabelProps>`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  color: var(${(props) => props.color});
  margin: 0;
  padding: 0;
  margin-top: 5px;
  display: inline-block;
`;
