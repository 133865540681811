import { IonButton, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "../../../../components/Icon";
import { Title, Label, ButtonText } from "../../../../components/Typography";
import { I18NKey } from "../../../../i18n";
import ANDROIDEBODY from "../../../../images/androide.svg";
import GINOIDEBODY from "../../../../images/ginoide.svg";
import YES from "../../../../images/yes.svg";
import NO from "../../../../images/no.svg";
import ANDROIDELOWER from "../../../../images/androide-lower.svg";
import GINOIDELOWER from "../../../../images/ginoide-lower.svg";
import MORNING from "../../../../images/morning.svg";
import EVENING from "../../../../images/evening.svg";
import { QuizAnswer } from "../../../../utils/const/quizAnswerEnum";

type Props = {
  index: number;
  btn_text: I18NKey;
  onNext: (answer: QuizAnswer) => void;
};

const images: {
  androide: string;
  ginoide: string;
  width?: string;
  height?: string;
  marginLeft?: string;
  marginTop?: string;
}[] = [
  { androide: ANDROIDEBODY, ginoide: GINOIDEBODY },
  {
    androide: YES,
    ginoide: NO,
    marginTop: "10px",
    marginLeft: "20px",
    width: "80px",
  },
  {
    androide: ANDROIDELOWER,
    ginoide: GINOIDELOWER,
    marginTop: "20px",
    marginLeft: "10px",
    width: "90px",
  },
  { androide: MORNING, ginoide: EVENING, width: "80px", marginLeft: "20px" },
  {
    androide: YES,
    ginoide: NO,
    marginTop: "10px",
    marginLeft: "20px",
    width: "80px",
  },
];

const QuizTemplate: React.FC<Props> = ({ index, btn_text, onNext }) => {
  const [answer, setAnswer] = useState<QuizAnswer>();
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <TitleContainer>
          <IonText className="special-title" color="black">
            <Title
              sizePx={28}
              weight={400}
              ntText={t(`quiz.${index}.question`)}
            />
          </IonText>
          <IonText></IonText>
        </TitleContainer>
        <QuizChoise onClick={() => setAnswer(QuizAnswer.ANDROIDE)}>
          <RadioContainer>
            <PseudoRadio selected={answer === QuizAnswer.ANDROIDE}>
              <Icon name="ok" color="white" size="18px" />
            </PseudoRadio>
          </RadioContainer>
          <AnswerContainer selected={answer === QuizAnswer.ANDROIDE}>
            <FigureImage
              style={{
                marginLeft: images[index].marginLeft,
                marginTop: images[index].marginTop,
                width: images[index].width,
                height: images[index].height,
              }}
              src={images[index].androide}
            />
          </AnswerContainer>
          <TextContainer>
            <IonText color="black">
              <Label
                weight={answer === QuizAnswer.ANDROIDE ? 800 : 400}
                sizePx={23}
                ntText={t(`quiz.${index}.androide_answer`)}
              />
            </IonText>
          </TextContainer>
        </QuizChoise>

        <QuizChoise onClick={() => setAnswer(QuizAnswer.GINOIDE)}>
          <RadioContainer>
            <PseudoRadio selected={answer === QuizAnswer.GINOIDE}>
              <Icon name="ok" color="white" size="18px" />
            </PseudoRadio>
          </RadioContainer>
          <AnswerContainer selected={answer === QuizAnswer.GINOIDE}>
            <FigureImage
              style={{
                marginLeft: images[index].marginLeft,
                marginTop: images[index].marginTop,
                width: images[index].width,
                height: images[index].height,
              }}
              src={images[index].ginoide}
            />
          </AnswerContainer>
          <TextContainer>
            <IonText color="black">
              <Label
                sizePx={23}
                weight={answer === QuizAnswer.GINOIDE ? 800 : 400}
                ntText={t(`quiz.${index}.ginoide_answer`)}
              />
            </IonText>
          </TextContainer>
        </QuizChoise>
      </Container>
      <NextContainer>
        <IonButton
          disabled={answer === undefined}
          onClick={() => {
            setAnswer(undefined);
            onNext(answer!);
          }}
          size="large"
        >
          <ButtonText text={btn_text} />
        </IonButton>
      </NextContainer>
    </>
  );
};

export default QuizTemplate;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;
const QuizChoise = styled.div`
  width: 100%;
  max-width: 400px;
  height: 170px;
  display: flex;
`;

const RadioContainer = styled.div`
  width: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  > * {
    margin-top: 60px;
  }
`;

const PseudoRadio = styled.div<{ selected?: boolean }>`
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 25px;
  :hover {
    cursor: pointer;
  }
  border: 1px solid
    ${(props) =>
      props.selected ? "var(--ion-color-white)" : "var(--ion-color-medium)"};
  background-color: ${(props) =>
    props.selected ? "var(--ion-color-primary)" : "var(--ion-color-white)"};
`;

const AnswerContainer = styled.div<{ selected?: boolean }>`
  width: 200px;
  margin-left: 10px;
  margin-top: auto;
  height: 60%;
  display: flex;
  background-color: ${(props) =>
    props.selected
      ? "var(--ion-color-primary-light)"
      : "var(--ion-color-light-tint)"};
  > img {
    max-width: 100px;
  }
`;

const FigureImage = styled.img`
  max-width: 100%;
  border: 0;
  position: relative;
  bottom: 30px;
  height: calc(100% + 30px);
`;

const TextContainer = styled.div`
  height: 100%;
  max-width: 180px;
  margin-left: -60px;
  display: flex;
  align-items: center;
  > * {
    margin-top: 40%;
  }
`;

const NextContainer = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0px 6px 8px 2px #222;
  > ion-button {
    width: 266px;
    height: 50px;
  }
`;
