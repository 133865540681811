import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'


export type RequestStatus = 'idle' | 'loading' | 'success' | 'error'
type RequestState<T> = {
  status: RequestStatus
  data: T | null
  error: string | null
}
export const useRequest = <T, P>(fetchFn: (params: P) => Promise<T>) => {
  const {t} = useTranslation()
  const [state, setState] = useState<RequestState<T>>({
    status: 'idle',
    data: null,
    error: null,
  })

  const request = useCallback(
    (params: P) => {
      setState((state) => ({ ...state, status: 'loading', error: null }))
      fetchFn(params)
        .then((data: T) => {
          setState({ data, error: null, status: 'success' })
        })
        .catch((error: Error) => {
          toast.error('error')
          setState({ data: null, error: t(error.message), status: 'error' })
        })
    },
    [fetchFn, t]
  )

  const reset = useCallback(() => setState({ data: null, error: null, status: 'idle' }), [])

  return useMemo(
    () => [request, state, reset] as [(params: P) => void, RequestState<T>, () => void],
    [request, state, reset]
  )
}
