import { IonApp, IonHeader } from "@ionic/react";
import { IonReactHashRouter, IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { AppRouter } from "./router";
import { UserHeader } from "./components/UserHeader";
import { setupIonicReact } from "@ionic/react";
import { Toaster } from "react-hot-toast";
// import "./mediaquery.css";

setupIonicReact();
const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            fontFamily: "'Open Sans', sans-serif",
          },
        }}
      />
      <AppRouter />
    </IonReactRouter>
  </IonApp>
);

export default App;
