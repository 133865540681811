import { IonContent, IonPage } from "@ionic/react";
import styled from "styled-components";
import { Header } from "../../../components/Header";
import { VerifyForm } from "../components/VerifyForm";

export const VerifyPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Container>
          <Header />
          <VerifyForm />
        </Container>
      </IonContent>
    </IonPage>
  );
};

const Container = styled.div``;
