import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonRow,
  IonText,
  IonToast,
  useIonToast,
} from "@ionic/react";
import {
  pin,
  wifi,
  wine,
  warning,
  walk,
  informationCircle,
} from "ionicons/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  color,
} from "@chakra-ui/react";
// import { CheckCircleIcon } from "@chakra-ui/icons";

import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast/dist/core/toast";
import { useToaster } from "react-hot-toast/dist/core/use-toaster";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";
import { BackLinkArrow } from "../../../components/BackLinkArrow";
import { Icon } from "../../../components/Icon";
import { Input } from "../../../components/Input";
import { ButtonText, Typography } from "../../../components/Typography";
import LOGO from "../../../images/header-logo.svg";
import { LocaitonState } from "../../../utils/const/LocationSate";
import { QuizAnswer } from "../../../utils/const/quizAnswerEnum";
import { useRequest } from "../../../utils/hooks/useRequest";
import { useSuccessToast } from "../../../utils/hooks/useSuccessToast";
import { quizAPI } from "../data/api";
import { useHistory } from "react-router";
import ANDROIDEBODY from "../../../images/androide.svg";
import GINOIDEBODY from "../../../images/ginoide.svg";
import GIULIA from "../../../images/Giulia.svg";
import { isMobile, browserName } from "react-device-detect";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const images: {
  androide: string;
  ginoide: string;
  giulia: string;
  width?: string;
  height?: string;
  marginLeft?: string;
  marginTop?: string;
}[] = [{ androide: ANDROIDEBODY, ginoide: GINOIDEBODY, giulia: GIULIA }];

export const Typo: React.FC = (props) => {
  // let stateTypo = "";
  const history = useHistory();

  let result = JSON.stringify(props);
  let tempData = JSON.parse(result);

  const [stateTypo, setStateTypo] = useState("");

  useEffect(() => {
    setStateTypo(tempData.location["state"]);
  }, []);

  const [showModal, setShowModal] = useState(true);

  function delay(time: any) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async function backToHome() {
    setStateTypo("");
    setShowModal(false);
    delay(1000).then(() => history.push("/"));
    // history.push("/");
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log(windowDimensions);

  return (
    <IonContent>
      <Container>
        <HeaderContainer>
          <HeaderLogo src={LOGO} />
        </HeaderContainer>
        <Box
          style={{
            height: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sx={{
            color: "grey.500",
            height: "100%",
            width: 300,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
        <StyledIonModal isOpen={showModal} onDidDismiss={backToHome}>
          <StyledContent>
            {isMobile ? (
              <IonRow className="ion-align-self-end">
                <Icon
                  name="exit"
                  size="20px"
                  color="black"
                  onClick={backToHome}
                />
              </IonRow>
            ) : (
              <IonRow className="ion-align-self-end">
                {/* <StyleIonCardHeader> */}
                <Icon
                  name="exit"
                  size="20px"
                  color="black"
                  onClick={backToHome}
                />
                {/* </StyleIonCardHeader> */}
              </IonRow>
            )}

            {/* <TopSection style={{ marginTop: isMobile ? "30px" : "5px" }}> */}
            <IonGrid
            // style={{ backgroundColor: "yellow", marginBottom: 0 }}
            >
              {isMobile ? (
                <IonRow
                // style={{ backgroundColor: "blue", marginBottom: 0 }}
                >
                  <IonCol
                    size="4"
                    className="ion-align-self-start"
                    // style={{ backgroundColor: "green", marginBottom: 0 }}
                  >
                    <FigureImage
                      style={{
                        marginLeft: 0,
                        marginTop: 0,
                      }}
                      src={
                        stateTypo === "androide"
                          ? images[0].androide
                          : images[0].ginoide
                      }
                    />
                  </IonCol>
                  <IonCol
                    size="8"
                    className="ion-align-self-end"
                    style={{ color: "black" }}
                  >
                    <IonText
                      className="special-title"
                      color="black"
                      style={{ marginTop: "0px" }}
                    >
                      <Typography sizePx={40} weight={400}>
                        {stateTypo === "androide"
                          ? "Sei Androide"
                          : "Sei Ginoide"}
                      </Typography>
                    </IonText>
                    <IonRow>
                      <IonCol style={{ textAlign: "center" }}>
                        <IonText color="dark">
                          <Typography color="dark" sizePx={15} weight={400}>
                            Benvenuta nel club &#128522;
                          </Typography>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    {/* <EmptyBox /> */}
                  </IonCol>
                </IonRow>
              ) : (
                <IonRow>
                  <IonCol
                    size="4"
                    className="ion-align-self-start"
                    // style={{ backgroundColor: "black" }}
                  >
                    <FigureImage
                      style={{
                        marginLeft: 0,
                        marginTop: 0,
                      }}
                      src={
                        stateTypo === "androide"
                          ? images[0].androide
                          : images[0].ginoide
                      }
                    />
                  </IonCol>
                  <IonCol
                    size="8"
                    className="ion-align-self-end"
                    style={{ color: "black" }}
                  >
                    <IonText
                      className="special-title"
                      color="black"
                      style={{ marginTop: "0px" }}
                    >
                      <Typography sizePx={40} weight={400}>
                        {stateTypo === "androide"
                          ? "Sei Androide"
                          : "Sei Ginoide"}
                      </Typography>
                    </IonText>
                    <IonRow>
                      <IonCol style={{ textAlign: "center" }}>
                        <IonText color="medium">
                          <Typography sizePx={15} weight={400}>
                            Benvenuta nel club &#128522;
                          </Typography>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <EmptyBox />
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>

            <IonInfiniteScroll
              // onIonInfinite={loadData}
              threshold="100px"
              // disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent>
                <InternalBox
                  style={{
                    // overflowY: windowDimensions.width < 400 ? "scroll" : null,

                    overflowY: "scroll",
                    // backgroundColor:
                    //   windowDimensions.width < 400 ? "yellow" : "red",
                  }}
                >
                  {/* <div style={{ overflow: "scroll" }}> */}
                  <IonText style={{ top: "0px" }}>
                    <Typography
                      sizePx={isMobile ? 13 : 14}
                      weight={400}
                      style={{
                        textAlign: "left",
                        whiteSpace: "pre-line",
                        marginTop: "5px",
                      }}
                    >
                      {stateTypo === "androide" ? (
                        <>
                          Le donne Androidi hanno la tendenza ad accumulare
                          grasso soprattutto sulla pancia ma anche sul dorso e
                          le braccia. L’ideale è allenarsi al mattino quando si
                          è più attive prediligendo esercizi mirati per
                          incrementare la massa magra e tonificare la parte
                          inferiore del corpo rendendo i glutei alti e rotondi e
                          le gambe snelle ma toniche. L’addome, punto critico
                          della donna Androide va allenato almeno tre volte a
                          settimana con circuiti mirati ad alta efficacia.
                        </>
                      ) : (
                        <>
                          Le donne Ginoidi hanno la tendenza ad accumulare
                          grasso a livello di glutei, fianchi e cosce. Hanno un
                          punto vita sottile ma soffrono spesso di cattiva
                          circolazione, ritenzione e cellulite. Per questo hanno
                          necessità di allenarsi evitando la corsa o strategie
                          come l’HIIT prediligendo esercizi a basso impatto che
                          vadano a migliorare la circolazione senza ingrossare
                          le gambe e senza stimolare troppo la produzione di
                          acido lattico che peggiorerebbe la cellulite. Il
                          segreto per un corpo armonioso e super sexy? Lavorare
                          in modo mirato anche sulla parte alta!
                        </>
                      )}
                    </Typography>
                  </IonText>

                  <IonText
                    color="primary"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <Typography sizePx={isMobile ? 13 : 14} weight={800}>
                      Vuoi scoprire come dimagrire e tonificare?
                    </Typography>
                  </IonText>

                  <IonText style={{ marginTop: "10px" }}>
                    <Typography
                      color="medium"
                      sizePx={isMobile ? 13 : 14}
                      weight={400}
                      style={{ textAlign: "left" }}
                    >
                      Non ti resta che leggere la mail che ti ho appena inviato
                      sulla tua casella di posta elettronica. Nel caso non la
                      trovassi, guarda nella casella SPAM o POSTA INDESIDERATA.
                    </Typography>
                  </IonText>
                  <IonText
                    // color="medium"
                    style={{
                      marginTop: "5px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      color="medium"
                      sizePx={isMobile ? 13 : 14}
                      weight={400}
                      style={{ textAlign: "left" }}
                    >
                      Un abbraccio,
                    </Typography>
                  </IonText>
                  <FirmaImage
                    style={{
                      marginTop: "0px",
                      marginLeft: "0px",
                      whiteSpace: "pre-line",
                      // backgroundColor: "coral",
                      width: "15%",
                      height: "10%",
                    }}
                    src={GIULIA}
                  />
                  {/* </div> */}
                </InternalBox>
              </IonInfiniteScrollContent>
            </IonInfiniteScroll>
            {/* </TopSection> */}
          </StyledContent>
        </StyledIonModal>
      </Container>
    </IonContent>
  );
};

// bottom: 30px;

const FigureImage = styled.img`
  max-width: 100%;
  border: 0;
  position: relative;
  bottom: 5px;
  height: calc(100% + 30px);
`;

const FirmaImage = styled.img`
  border: 0;
  bottom: 30px;
  height: 20px;
`;

const Container = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InternalBox = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78px;
`;

const EmptyBox = styled.div`
  height: 50px;
  width: 200px;
`;

const HeaderLogo = styled.img``;

const StyledIonModal = styled(IonModal)`
  background-color: transparent;
  height: 100%;
  color: #222222;
  --border-radius: 32px !important;
`;

const StyledContent = styled.div`
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 22px;
`;

const TopSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const StyleIonCardHeader = styled(IonCardHeader)`
  display: flex;
  width: 100%;
  justify-content: end;
`;

const StyleIonCardHeaderMobile = styled(IonCardHeader)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
`;
