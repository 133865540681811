import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";

import { WelcomePage } from "./pages/Welcome";

export const LandingPageRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route path={path} exact>
        <Redirect to={`${path}/welcome`} />
      </Route>
      <Route path={`${path}/welcome`} exact>
        <WelcomePage />
      </Route>
    </>
  );
};
