import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { GetResultPage } from "./pages/GetResult";
import { QuizPage } from "./pages/QuizPage";
import { Typo } from "./pages/Typo";

export const QuizRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route path={`${path}/`} exact>
        <QuizPage />
      </Route>
      <Route path={`${path}/results`} exact>
        <GetResultPage />
      </Route>
      <Route path={`${path}/typo`} exact component={Typo} />
      {/* <Typo />
      </Route> */}
    </>
  );
};
