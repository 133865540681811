import { IonApp, IonContent, IonPage } from "@ionic/react";
import { AppLinks } from "../../../components/AppLinks";
import { Header } from "../../../components/Header";
import { HomeLayout } from "../../../components/HomeLayout";
import { LoginForm } from "../components/LoginForm";

export const LoginPage: React.FC = () => {
  return (
    <IonApp>
      <IonContent fullscreen>
        <HomeLayout>
          <Header topText="landing_page.welcome" />
          <LoginForm />
          <AppLinks />
        </HomeLayout>
      </IonContent>
    </IonApp>
  );
};
