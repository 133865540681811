import React, { useContext } from 'react'
import { CSSObject } from 'styled-components'

import { IconSelect, IconName, iconNames as _iconNames } from '../utils/assets/icons/GeneratedIcons'

export type IconNames = IconName
export const iconNames = _iconNames

type Props = {
  name: IconName
  color?: CSSObject['color']
  size?: string
  onClick?: (ev: React.MouseEvent) => void
  style?: Record<string, unknown>
  className?: string
}
export const Icon = ({ name, color = 'white', size = '1em', onClick, style, className }: Props) => {
  return (
    <IconSelect
      name={name}
      iconProps={{
        color: color,
        style: { width: size, height: size, ...style },
        className,
        onClick: onClick || undefined,
      }}
    />
  )
}
