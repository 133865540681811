import { IonButton, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ButtonText } from "../../../components/Typography";
import { useRequest } from "../../../utils/hooks/useRequest";
import { authAPI } from "../data/api";
import { Redirect, useHistory, useLocation } from "react-router";
import { Input } from "../../../components/Input";
import { storage } from "../../../data/storage";
import { useEffect, useState } from "react";

export const LoginForm: React.FC = () => {
  const [login, { data, status }] = useRequest(authAPI.login);
  const history = useHistory();
  const location = useLocation();
  const [valid , setValid]= useState(false)
  const { register, handleSubmit } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (status === "success" && data) {
      storage.saveUser(data.user);
      storage.saveToken(data.token);
      setValid(true)
      
    }
  }, [data, status]);

  return (
    <Container
      onSubmit={handleSubmit((variables) => {
        return login({ email: variables.email, password: variables.password });
      })}
    >
     {valid && <Redirect to={{pathname: "/dogs", state: { userDefined : true}}} />} 
      <Input
        name="email"
        placeholder="auth.email_placeholder"
        label="auth.email"
        innerRef={register}
        size="large"
      />
      <Input
        name="password"
        placeholder="auth.password_placeholder"
        label="auth.password"
        size="large"
        innerRef={register}
        type="password"
      />
      <ButtonPadding>
        <IonButton type="submit" expand="block" color="secondary" size="large">
          {status !== "loading" && <ButtonText text="auth.login" />}
          {status === "loading" && <IonSpinner name="crescent" color="light" />}
        </IonButton>
      </ButtonPadding>
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;
  > * {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 30px;
  }
`;

const ButtonPadding = styled.div`
  width: 100%;
  padding: 0 30px;
  margin-top: 20px;
`;
