import { IonApp, IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { isMobile, browserName } from "react-device-detect";
import styled from "styled-components";
import {
  Label,
  Subtitle,
  Title,
  Text,
  ButtonText,
} from "../../../components/Typography";
import { Trans, useTranslation } from "react-i18next";
import LANDINGIMAGE from "../../../images/FITISBEAUTY-50.png";
import LOGO from "../../../images/header-logo.svg";
export const WelcomePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <IonApp>
      <IonContent>
        <Container>
          <HeaderBar>
            {isMobile ? (
              <HeaderLogo style={{ marginTop: 30 }} src={LOGO} />
            ) : (
              <HeaderLogo
                style={{ marginBottom: 20, marginTop: 10 }}
                src={LOGO}
              />
            )}
          </HeaderBar>
          <HeaderContainer>
            <TitleContainer>
              <IonText className="special-title title" color="black">
                <Title sizePx={56} weight={400} text="landing_page.title" />
              </IonText>
              <IonText className="subtitle" color="medium">
                <Subtitle
                  sizePx={24}
                  weight={400}
                  text="landing_page.subtitle"
                />
              </IonText>
            </TitleContainer>
            <ImageContainer>
              <ImageBox />
              <Box />
            </ImageContainer>
          </HeaderContainer>
          <BodyContainer>
            <TextContainer>
              <IonText style={{ marginBottom: "5px" }} color="primary">
                <Text weight={800} text="landing_page.introduction1" />
              </IonText>
              <IonText>
                <Text text="landing_page.introduction2">
                  <Text weight={800} text="landing_page.introduction2_1" />
                </Text>
              </IonText>

              <IonText>
                <Text text="landing_page.introduction3">
                  <b>{t("landing_page.introduction3_1")}</b>
                  {t("landing_page.introduction3_2")}
                  <b> {t("landing_page.introduction3_3")}</b>
                  {t("landing_page.introduction3_4")}
                </Text>
              </IonText>
            </TextContainer>
            <TextContainer>
              <IonText>
                <Text text="landing_page.introduction4">
                  <b>{t("landing_page.introduction4_1")}</b>
                  {t("landing_page.introduction4_2")}
                </Text>
              </IonText>
              <IonText style={{ marginBottom: "5px" }} color="primary">
                <Text weight={800} text="landing_page.introduction5" />
              </IonText>
              <IonText>
                <Text text="landing_page.introduction6">
                  <b>{t("landing_page.introduction6_1")}</b>
                </Text>
              </IonText>
            </TextContainer>
          </BodyContainer>
          <NextContainer>
            <IonButton onClick={() => history.push("/quiz")} size="large">
              <ButtonText text="landing_page.start_test" />
            </IonButton>
          </NextContainer>
        </Container>
      </IonContent>
    </IonApp>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  justify-content: space-around;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    width: 250px;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    > * {
      text-align: center;
      width: unset;
    }
    .title > * {
      font-size: 28px !important;
    }
    .subtitle > * {
      font-size: 17px !important;
    }
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  height: 400px;
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 300px;
    flex-flow: wrap;
  }
`;

const Box = styled.div`
  width: 188px;
  height: 366px;
  position: relative;
  left: -80%;
  z-index: 100;
  background-color: var(--ion-color-primary);
  @media only screen and (max-width: 800px) {
    width: 130px;
    height: 90%;
    left: 0;
    top: -100%;
  }
`;

const ImageBox = styled.div`
  width: calc(100% - 60px);
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  z-index: 101;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${LANDINGIMAGE});
  @media only screen and (max-width: 800px) {
    margin-left: 20px;
    height: 270px;
    background-size: cover;
  }
`;

const TextContainer = styled.div`
  padding: 20px;
  width: 440px;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 800px) {
    width: calc(100% - 40px);
  }
`;

const NextContainer = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: center;
  background-color: var(--ion-color-white);
  padding: 20px 0;
  box-shadow: 0px 6px 8px 2px #222;
  > ion-button {
    width: 266px;
    height: 50px;
  }
`;

const HeaderBar = styled.div`
  width: 100%;
  display: flex;
  height: 78px;
  justify-content: center;
  align-items: end;
  box-shadow: 0px -6px 8px 2px #222;
  margin-bottom: 30px;
  padding-bottom: 10px;
`;

const HeaderLogo = styled.img``;
