import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AppStoreBadge from "../images/app-store.svg";
import PlayStoreBadge from "../images/play-store.png";

export const AppLinks: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Link to="https://www.neewa.it"> {t("landing_page.download_app")} </Link>
      <BadgesContainer>
        <img id="appStore" src={AppStoreBadge} />
        <img id="playStore" src={PlayStoreBadge} />
      </BadgesContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 30px;
  a {
    font-weight: 600;
    font-size: 17px;
    margin-top: 40px;
    line-height: 25px;
  }
`;

const BadgesContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  > #appStore {
    padding-left: 10px;
    width: 160px;
    height: auto;
  }
  > #playStore {
    width: 190px;
    height: auto;
  }
  @media only screen and (max-width: 800px){
    width: fit-content;
    flex-direction: column;
    > #appStore {
    width: 150px;
    padding-left: 0px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:20px;
  }
  > #playStore {
    width: 175px;
    height: auto;
  }
  }
`;
