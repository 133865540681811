import { Dog, User } from "./models";

class Storage {
  getToken = () => localStorage.getItem("jwt");
  saveToken = (token: string) => localStorage.setItem("jwt", token);
  deleteToken = () => localStorage.removeItem("jwt");

  getUser = () => JSON.parse(localStorage.getItem("user") || "null") as User;
  saveUser = (user: User) => localStorage.setItem("user", JSON.stringify(user));
  deleteUser = () => localStorage.removeItem("user");

  getMode = () => localStorage.getItem('mode') ?? 'light'
  setMode = (mode : 'light' | 'dark') => localStorage.setItem('mode', mode)

  getDog = () =>
    JSON.parse(localStorage.getItem("dog") || "null") as Partial<Dog>;
  saveDog = (dog: Partial<Dog>) =>
    localStorage.setItem("dog", JSON.stringify(dog));
  deleteDog = () => localStorage.removeItem("dog");
}
export const storage = new Storage();
