import { IonContent, IonHeader, IonMenu, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Menu } from "../components/Menu";
import { storage } from "../data/storage";
import { AuthRouter } from "../modules/auth/Router";
import { LandingPageRouter } from "../modules/landingPage/Router";
import { QuizRouter } from "../modules/quiz/Router";

export const AuthenticatedRoute = ({
  header,
  component: Component,
  ...rest
}: {
  component: React.ElementType;
  [key: string]: any;
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return storage.getToken() !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        );
      }}
    />
  );
};

export const AppRouter = () => {
  return (
    <>
      {/* <IonMenu contentId="main" side="start"> */}
      {/* <IonContent>
          <Menu />
        </IonContent> */}
      {/* </IonMenu> */}
      {/* <IonRouterOutlet id="main"> */}
      <Switch>
        <Route path="/landing">
          <LandingPageRouter />
        </Route>
        <Route path="/auth">
          <AuthRouter />
        </Route>
        <Route path="/quiz">
          <QuizRouter />
        </Route>
        <Route path="/">
          <Redirect to="/landing" />
        </Route>
      </Switch>
      {/* </IonRouterOutlet> */}
    </>
  );
};
