import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonToast,
  useIonToast,
} from "@ionic/react";
import {
  pin,
  wifi,
  wine,
  warning,
  walk,
  informationCircle,
} from "ionicons/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
// import { CheckCircleIcon } from "@chakra-ui/icons";

import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast/dist/core/toast";
import { useToaster } from "react-hot-toast/dist/core/use-toaster";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";
import { BackLinkArrow } from "../../../components/BackLinkArrow";
import { Icon } from "../../../components/Icon";
import { Input } from "../../../components/Input";
import { ButtonText, Typography } from "../../../components/Typography";
import LOGO from "../../../images/header-logo.svg";
import { LocaitonState } from "../../../utils/const/LocationSate";
import { QuizAnswer } from "../../../utils/const/quizAnswerEnum";
import { useRequest } from "../../../utils/hooks/useRequest";
import { useSuccessToast } from "../../../utils/hooks/useSuccessToast";
import { quizAPI } from "../data/api";
import { useHistory } from "react-router";

export const GetResultPage: React.FC = () => {
  const history = useHistory();
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [sendResultEmail, { status }] = useRequest(quizAPI.sendResults);
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ first_name: string; last_name: string; email: string }>({
    mode: "onChange",
  });

  const location = useLocation<LocaitonState>();
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [typoStatus, setTypoStatus] = useState("");

  async function backToHome() {
    setShowModal(false);
    delay(1000).then(() => history.push("/"));
  }

  async function goToAliens(typo: any) {
    setShowModal(false);
    delay(1000).then(() =>
      history.push({
        pathname: "/quiz/typo",
        state: typo,
      })
    );
  }

  function delay(time: any) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  return (
    <IonContent>
      <Container>
        <BackLinkArrow color="var(--ion-color-dark)" />
        <HeaderContainer>
          <HeaderLogo src={LOGO} />
        </HeaderContainer>
        <TitleContainer>
          <IonText className="special-title" color="black">
            <Typography sizePx={34} weight={400} text="quiz.get_result" />
          </IonText>
          <IonText color="medium">
            <Typography sizePx={15} weight={400} text="quiz.insert_data" />
          </IonText>
        </TitleContainer>
        <FormContainer
          onSubmit={handleSubmit(async (data) => {
            let typoResponse =
              _.filter(
                location.state.quizAnswers,
                (answer) => answer === QuizAnswer.GINOIDE
              ).length > 2
                ? QuizAnswer.GINOIDE
                : QuizAnswer.ANDROIDE;

            // console.log(location.state.quizAnswers);

            sendResultEmail({
              ...data,
              result:
                _.filter(
                  location.state.quizAnswers,
                  (answer) => answer === QuizAnswer.GINOIDE
                ).length > 2
                  ? QuizAnswer.GINOIDE
                  : QuizAnswer.ANDROIDE,
            });
            setTypoStatus(typoResponse);
            goToAliens(typoResponse);
          })}
        >
          <InputContainer>
            <Input
              required
              label="auth.first_name"
              innerRef={register}
              placeholder="auth.first_name_placeholder"
              name="first_name"
              rightWidget={
                <IconContainer>
                  <Icon name="user" color="var(--ion-color-dark)" />
                </IconContainer>
              }
            />
            <Input
              required
              label="auth.last_name"
              innerRef={register}
              placeholder="auth.last_name_placeholder"
              name="last_name"
              rightWidget={
                <IconContainer>
                  <Icon name="user" color="var(--ion-color-dark)" />
                </IconContainer>
              }
            />
            <Input
              innerRef={register}
              required
              label="auth.email"
              type="email"
              placeholder="auth.email_placeholder"
              name="email"
              rightWidget={
                <IconContainer>
                  <Icon name="email" color="var(--ion-color-dark)" />
                </IconContainer>
              }
            />
          </InputContainer>
          <CheckContainer>
            <IonRow>
              <IonCheckbox
                mode="md"
                value={checkedPrivacy}
                onClick={() => setCheckedPrivacy(!checkedPrivacy)}
              />

              <IonCol></IonCol>
              <PrivacyLink
                href="https://firebasestorage.googleapis.com/v0/b/fit-is-beauty.appspot.com/o/quiz%2FPrivacy_Policy.pdf?alt=media&token=49907783-f8c1-4d88-a0f6-5fd57b213b36"
                // href="https://www.iubenda.com/privacy-policy/11313740"
                target="_blank"
              >
                <IonText color="var(--ion-color-dark)">
                  <Typography
                    sizePx={13}
                    weight={400}
                    text="quiz.accept_privacy"
                    style={{ textDecoration: "underline" }}
                  />
                </IonText>
              </PrivacyLink>
            </IonRow>
          </CheckContainer>
          <IonButton
            size="large"
            type="submit"
            disabled={!isValid || !checkedPrivacy || emailSent}
          >
            <ButtonText text="quiz.recive_result" />
          </IonButton>
        </FormContainer>
        {/* <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton> */}
        {/* <IonContent> */}
        <StyledIonModal isOpen={showModal} onDidDismiss={() => {}}>
          <StyledContent>
            <StyleIonCardHeader>
              <Icon
                name="exit"
                size="20px"
                color="black"
                onClick={() => setShowModal(false)}
              />
            </StyleIonCardHeader>

            <TopSection>
              <Icon name="check" />

              <IonText
                className="special-title"
                color="black"
                style={{ marginTop: "96px" }}
              >
                <Typography
                  sizePx={24}
                  weight={400}
                  text="quiz.final_question.androide"
                />
              </IonText>

              <IonText
                className="special-title"
                color="black"
                style={{ marginTop: "36px" }}
              >
                <Typography
                  sizePx={24}
                  weight={400}
                  text="quiz.final_question.ginoide"
                />
              </IonText>
            </TopSection>

            <IonRow style={{ flexBasis: "auto", marginBottom: "64px" }}>
              <IonCol size="3"></IonCol>
              <IonCol>
                <StyledCloseIonButton
                  onClick={() => {
                    goToAliens(typoStatus);
                  }}
                >
                  Scopri il risultato
                </StyledCloseIonButton>
              </IonCol>

              <IonCol size="3"></IonCol>
            </IonRow>
          </StyledContent>
        </StyledIonModal>
        {/* </IonContent> */}
      </Container>
    </IonContent>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78px;
`;

const HeaderLogo = styled.img``;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
`;

const IconContainer = styled.div`
  padding: 5px 0 5px 10px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  order: -1;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 16px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  > * {
    margin-bottom: 20px;
  }
`;

// justify-content: space-around;

const CheckContainer = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;

  margin-bottom: 40px;
  > * > * > * {
    color: var(--ion-color-dark);
    text-decoration: underline !important;
  }
`;

const PrivacyLink = styled.a``;

const SentContainer = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
`;

const StyledIonModal = styled(IonModal)`
  background-color: transparent;
  height: 100%;
  color: #222222;
  --border-radius: 32px !important;
`;

const StyledCloseIonButton = styled(IonButton)`
  width: 250px;
  text-align: center;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 32px;
`;

const TopSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const StyleIonCardHeader = styled(IonCardHeader)`
  display: flex;
  width: 100%;
  justify-content: end;
`;
