import { IonButton, IonCheckbox, IonSpinner, IonText } from "@ionic/react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ButtonText, Subtitle, Text } from "../../../components/Typography";
import { useRequest } from "../../../utils/hooks/useRequest";
import { authAPI } from "../data/api";
import { Redirect, useHistory, useLocation } from "react-router";
import { Input } from "../../../components/Input";
import { storage } from "../../../data/storage";
import { useEffect, useState } from "react";
import { VerifyUser } from "../../../data/models";
import { BackLinkArrow } from "../../../components/BackLinkArrow";
import { LocaitonState } from "../../../utils/const/LocationSate";

export const VerifyForm: React.FC = () => {
  const [verify, { data, status }] = useRequest(authAPI.generateAnonuser);
  const [read, setRead] = useState(false);
  const history = useHistory();
  const location = useLocation<LocaitonState>();
  const { register, handleSubmit, getValues } = useForm<VerifyUser>({
    mode: "onChange",
  });

  useEffect(() => {
    if (status === "success" && data) {
      history.push("/auth/resend-email", {
        ...location.state,
        email: getValues("email"),
        first_name: getValues("first_name"),
        last_name: getValues("last_name"),
      });
    }
  }, [data, status]);

  useEffect(() => {
    console.log(location.state);
  }, [location.state]);

  return (
    <Container
      onSubmit={handleSubmit((variables) => {
        console.log("+++++++++++++++++");
        verify({
          body: variables,
          recource: location.state.resource,
          id: location.state.id,
        });
      })}
    >
      <BackLinkArrow />
      <IonText color="primary">
        <Subtitle text="auth.register" />
      </IonText>
      <Input
        name="email"
        required
        placeholder="auth.email_placeholder"
        label="auth.email"
        innerRef={register}
        size="large"
      />
      <Input
        name="first_name"
        required
        placeholder="auth.first_name_placeholder"
        label="auth.first_name"
        innerRef={register}
        size="large"
      />
      <Input
        name="last_name"
        placeholder="auth.last_name_placeholder"
        label="auth.last_name"
        innerRef={register}
        size="large"
      />
      <PrivacyContainer>
        <IonText color="secondary">
          <Text weight={600} text="auth.privacy_policy" />
        </IonText>
        <IonCheckbox
          mode="md"
          color="secondary"
          checked={read}
          onIonChange={() => setRead(!read)}
        />
      </PrivacyContainer>

      <ButtonPadding>
        <IonButton type="submit" expand="block" color="secondary" size="large">
          {status !== "loading" && <ButtonText text="auth.login" />}
          {status === "loading" && <IonSpinner name="crescent" color="light" />}
        </IonButton>
      </ButtonPadding>
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;
  > * {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 30px;
  }
`;

const ButtonPadding = styled.div`
  width: 100%;
  padding: 0 30px;
  margin-top: 60px;
`;

const PrivacyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;
