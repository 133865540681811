import { IonApp, IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { BackActionArrow } from "../../../components/BackActionArrow";
import { LocaitonState } from "../../../utils/const/LocationSate";
import { QuizAnswer } from "../../../utils/const/quizAnswerEnum";
import QuizTemplate from "../components/quizTemplate";
// import "../../../mediaquery.css";

export const QuizPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocaitonState>();
  const [quizAnswers, setQuizAnswers] = useState<QuizAnswer[]>([]);
  const [progress, setProgress] = useState(0);
  const updateProgress = useCallback(() => {
    setProgress(quizAnswers.length);
  }, [quizAnswers]);

  const addAnswer = useCallback(
    (answer: QuizAnswer) => {
      const updatedAnswers = quizAnswers;
      updatedAnswers.push(answer);
      setQuizAnswers(updatedAnswers);
      if (quizAnswers.length >= 5) {
        history.push("/quiz/results", {
          quizAnswers,
        });
        return;
      }
      updateProgress();
    },
    [quizAnswers, updateProgress]
  );

  const removeAnswer = useCallback(() => {
    const updatedAnswers = quizAnswers;
    updatedAnswers.pop();
    setQuizAnswers(updatedAnswers);
    if (quizAnswers.length === 0) {
      history.push("/landing");
      return;
    }
    updateProgress();
  }, [quizAnswers, updateProgress]);

  return (
    <IonContent>
      <BackActionArrow
        color={"var(--ion-color-dark)"}
        action={() => removeAnswer()}
      ></BackActionArrow>
      <ProgressBarContainer>
        <ProgressBar>
          <Progress id="progress" left={progress}></Progress>
        </ProgressBar>
      </ProgressBarContainer>

      <QuizTemplate
        index={progress}
        btn_text="actions.next"
        onNext={(answer: QuizAnswer) => addAnswer(answer)}
      />
    </IonContent>
  );
};

const ProgressBarContainer = styled.div`
  width: 100%;
  padding: 20px 20px;
  height: 78px;
  box-shadow: 0px -6px 8px 2px #222;
  display: flex;
  justify-content: center;
`;

const ProgressBar = styled.div`
  background-color: var(--ion-color-light);
  height: 15px;
  border-radius: 20px;
  max-width: 1000px;
  width: 100%;
  overflow-x: hidden;
  @media only screen and (max-width: 1050px) {
    width: calc(100% - 50px);
    margin-left: auto;
  }
`;

const Progress = styled.div<{ left: number }>`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 20px;
  background-color: var(--ion-color-primary);
  left: calc(-100% + ${(props) => `${(props.left + 1) * 16.5}`}%);
  transition: 0.5s left cubic-bezier(0, 1.02, 0.74, 1.02);
`;
