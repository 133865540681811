import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { VerifyPage } from "./pages/Verify";

export const AuthRouter = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route path={path} exact>
        <Redirect to={`${path}/login`} />
      </Route>
      <Route path={`${path}/login`} exact> 
        <LoginPage/>
      </Route>
      <Route path={`${path}/verify`} exact> 
        <VerifyPage/>
      </Route>
    </>
  );
};
