import { storage } from "../../../data/storage";
import { api } from "../../../data/api";
import { User, VerifyUser } from "../../../data/models";

class AuthAPI {
  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token);
    storage.saveUser(res.user);
    return res;
  };

  public logout = () => {
    storage.deleteToken();
    storage.deleteUser();
  };

  public login = ({ email, password }: { email: string; password: string }) =>
    api
      .post<LoginResponse>("/auth/login", { email, password }, false)
      .then(this.persistSession);

  public recoverPassword = ({ email }: { email: string }) =>
    api.post<{}>("/auth/otp/request", { email }, false);

  public loginOTP = (body: { email: string; otp: string }) =>
    api
      .post<LoginResponse>("/auth/otp/login", body, false)
      .then(this.persistSession);

  public generateAnonuser = (params: {
    body: VerifyUser;
    recource: string;
    id?: string;
  }) =>
    api.post<LoginResponse>(
      `/auth/generate-anon-session?resource=${params.recource}${
        params.id ? `&id=${params.id}` : ""
      }`,
      params.body,
      false
    );

  public checkUniqueEmail = ({ email }: { email: string }) =>
    api.post<{}>("/auth/check_unique", { email }, false);

  public updatePassword = async ({
    password,
    session,
  }: {
    password: string;
    session?: LoginResponse;
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session);
    }
    return api.put<User>("/me/password", { password }).catch((err) => {
      if (session) {
        this.logout();
      }
      throw err;
    });
  };
}

export type LoginResponse = { token: string; user: User };

export const authAPI = new AuthAPI();
